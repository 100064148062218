<template>
    <div>
        <!-- <div id="misc">
            <div class="page-title text-center px-5">
                <h2 class="text-2xl font-weight-semibold text--primary d-flex align-center justify-center">
                    <span class="me-2">No post yet</span>
                    <v-icon color="warning">
                        {{ icons.mdiAlert }}
                    </v-icon>
                </h2>
                <p class="text-sm">
                    The blog page will comming soon..
                </p>

                <div class="misc-character d-flex justify-center">
                    <v-img max-width="700" src="@/assets/images/pages/cuve-line.svg"></v-img>
                </div>

                <v-btn color="primary" :to="{ name: 'user.home' }" class="mb-4">
                    Back to home
                </v-btn>
            </div>
        </div> -->
        <header class="bg-gray-50 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-8">
            <div class="flex flex-wrap items-center justify-between mx-6 my-4">
                <h2 class="mr-10 text-4xl font-bold leading-none md:text-5xl">
                    Blog
                </h2>
                <div class="block pb-1 mt-2">
                    <v-btn text class="subsecondary" color="secondary rounded-lg">
                        <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                        <span>My post</span>
                    </v-btn>
                    <v-btn text class="subsecondary ml-2" color="secondary rounded-lg" @click="addNewBlog">
                        <v-icon>{{ icons.mdiShapeRectanglePlus }}</v-icon>
                        <span>Mew post</span>
                    </v-btn>
                </div>
            </div>
        </header>
        <section class="relative bg-gray-50 mt-4 pa-6 pb-20 lg:pa-8 lg:pb-28">
            <!-- <div class="absolute inset-0">
                <div class="h-1/3 bg-white sm:h-2/3"></div>
            </div> -->

            <div class="relative max-w-8xl animate-pulse" v-if="loader.post">
                <div class="grid max-w-7xl gap-5 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <div class="w-full" v-for="i in 8">
                        <v-skeleton-loader class="mx-auto border" max-width="300"
                            type="table-heading, image, article"></v-skeleton-loader>
                    </div>
                </div>
            </div>
            <div class="relative max-w-8xl" v-else>
                <!-- <div class="text-center">
                    <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Column me neatly.</h2>
                    <p class="mx-auto mt-3 max-w-2xl text-xl text-gray-500 sm:mt-4">
                        This is your life and it's ending one minute @ a time...</p>
                </div> -->
                <div class="grid max-w-7xl gap-5 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <blog-card v-for="(postblog, i) in bloglist" :key="i" :blog="postblog" ></blog-card>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiCamera, mdiSend, mdiCloseThick } from '@mdi/js'
import { Drequest } from '@/plugins/Drequest'
import { VueEditor } from "vue2-editor";
// import BlogueCard from '@/views/pages/company/components/BlogueCard.vue'
import PickPhoto from '@/views/pages/components/PickPhoto.vue'
import EmptyData from '@/views/components/utils/EmptyData.vue';
import BlogCard from '@/views/dashboard/blog/components/BlogCard.vue';

// var md5 = require("md5")
export default {
    components: {
        PickPhoto,
        // BlogueCard,
        EmptyData,
        BlogCard,
        VueEditor
    },
    data() {
        return {
            inputmessage: '',
            lisOfPostmessage: [],
            image: '',
            file: null,
            bloglist: [],
            nodatas: 'post',
            loader: {
                post: false,
                users: false
            }
        };
    },
    created() {
        // this.initialize()
    },
    methods: {
        addNewBlog(e) {
            this.$router.push({ name: 'user.blog.new' })
        },
        createImage(file) {
            var image = new Image();
            var reader = new FileReader();
            var vm = this;
            reader.onload = (e) => {
                vm.image = e.target.result;
            };
            reader.readAsDataURL(file);
        },

        showfiledata(e) {
            this.file = e.file
            this.createImage(e.file)
        },

        sendMessageBlog() {
            const post = {
                owner: this.$store.getters.getUser.firstname,
                content: this.inputmessage,
                sent_at: Date().toLocaleString(),
                isloaded: false,
                imag: this.image
            }
            this.image = ''
            this.inputmessage = ''
            this.lisOfPostmessage.push(post)

        },


        removeImage: function (e) {
            this.image = '';
        },
        uploadAnSendImage() {
            var formdata = new FormData();
            console.log(this.mfiles)
            formdata.append("image", this.mfiles);
            Drequest.api("fileupload")
                .data(formdata)
                .post((response) => {
                    if (response.success) {
                        // service_id
                        const imageToDB = {
                            "serviceimage": {
                                "name": response.file.name,
                                "description": this.imgdescription,
                                "path": 'test',
                            }
                        }
                        Drequest.api("serviceimage.attach")
                            .data(imageToDB)
                            .raw((respons) => {
                                if (respons.success) {
                                    this.$fire({
                                        type: "success",
                                        text: "Image succeful uploaded..!",
                                        timer: 2000
                                    })
                                    this.loadingData = false
                                }
                                else {
                                    this.$fire({
                                        type: "error",
                                        text: "Error occured..!",
                                        timer: 2000
                                    })
                                }
                            })
                            .catch((err) => {
                                console.log("err")
                                this.loadingData = false;
                            })
                        this.loadingData = false
                    }
                    else {
                        this.$fire({
                            type: "error",
                            text: "Error occured..!",
                            timer: 2000
                        })
                    }
                })
                .catch((err) => {
                    console.log("err")
                    this.loadingData = false;
                })
        },

        loadBlogPosts() {

            Drequest.api("lazyloading.post?dfilters=on&next=1&per_page=6")
                .get((response) => {
                    if (response.success === true) {
                        response.listEntity.forEach((value, index) => {
                            this.bloglist.push(value)
                        })
                        this.loader.users = false
                    }
                })
                .catch((err) => {
                    console.log(err)
                    this.loader.users = false;
                })
        },
    },
    computed: {
        sortRecipientsItems() {
            return this.lisOfPostmessage
        },
    },
    beforeMount() {
        this.loadBlogPosts()
    },
    setup() {
        const icons = { mdiMagnify, mdiCamera, mdiSend, mdiCloseThick }
        return {
            icons,
        }
    },
}
</script>

<style lang="scss" scoped>
.blog-vue-editor {
    width: 100%;
    height: 6em;
}
</style>