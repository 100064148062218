<template>

    <div class="flex flex-col overflow-hidden rounded shadow-lg">
        <div class="ma-3 flex items-center">
            <div class="flex-shrink-0">
                <a href="#">
                    <span class="sr-only">{{blog.user.lastname}}</span>
                    <img class="h-10 w-10 rounded-full" :src="require('@/assets/images/avatars/profil.jpg')" alt="">
                </a>
            </div>
            <div class="ml-3">
                <div class="text-sm font-medium text-gray-900">
                    <a href="#" class="hover:underline">{{blog.user.lastname}}</a>
                </div>
                <div class="flex space-x-1 text-sm text-gray-500">
                    <time datetime="2020-03-16">{{blog.created_at}}</time>
                    <span aria-hidden="true">·</span>
                    <span v-if="blog.reading_time">{{ blog.reading_time.minutes? blog.reading_time.minutes + '' + blog.reading_time.secondes:blog.reading_time.secondes }} read</span>
                </div>
            </div>
        </div>
        <div class="flex-shrink-0">
            <img class="h-36 w-full object-cover" :src="require('@/assets/images/bgs/profile-bnr.jpg')" alt="">
        </div>
        <div class="flex flex-1 flex-col justify-between bg-white p-6">
            <div class="flex-1">
                <p class="text-sm font-medium text-indigo-600">
                    <a href="#" class="hover:underline">Article</a>
                </p>
                <a href="#" class="mt-2 block">
                    <p class="text-md font-semibold text-gray-900">{{blog.title}}</p>
                    <p class="text-sm text-gray-500">
                        {{blog.subcontent}}
                    </p>
                </a>
                <div class="text-md font-medium text-indigo-600">
                    <router-link :to="{ name: 'user.blog.detail', params: { keyword: blog.id } }" class="hover:underline">Read more..</router-link>
                    <!-- <a :href="{ name: "user.blog.detail"}" class="hover:underline">Read more..</a> -->
                </div>
            </div>
        </div>
    </div>
</template>
<script>

// eslint-disable-next-line object-curly-newline
import { mdiPlay } from '@mdi/js'
export default {
    props: {
        blog: {
            type: Object,
            default: () => {
                content: "Default description"
            }
        }
    },
    data: () => ({
        absolute: true,
        overlay: false,
    }),

    methods: {
        goToreferenceLink(value) {
            this.$router.push(link)
        }
    },
    setup() {
        const icons = { mdiPlay }
        return {
            icons
        }
    },
}
</script>

<style lang="scss" scoped>
</style>